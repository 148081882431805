<template>
    <!-- <div class="about"> -->
    <!-- <a-scene id="as"
      mindar-image="imageTargetSrc: https://hzag.ustyle.cn/ar/targets3.mind;https://hzag.ustyle.cn/ar/targets.mind;https://hzag.ustyle.cn/ar/targets2.mind;filterMinCF:0.0001; filterBeta: 0.001;"
      color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false" animationFrameRate="60"> -->
    <a-scene id="as"
        :mindar-image="`imageTargetSrc: static/targets3.mind;filterMinCF:0.0001; filterBeta: 0.001;uiError: no; uiLoading:no; uiScanning:no;`"
        color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false" animationFrameRate="60">
        <a-assets>
            <a-asset-item id="avatarModel"
                src="https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1685505202203.glb"></a-asset-item>
        </a-assets>
        <a-camera position="0 0 10000" look-controls="enabled: false"></a-camera>
        <a-entity mindar-image-target="targetIndex: 0">
            <!-- <a-gltf-model id="model" rotation="0 0 0 " position="0 0 0.1" scale="0.005 0.005 0.005"
              animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"> -->
            <a-entity id="model" rotation="1 -2.5 0 " position="0 -1 0.1" modify-materials play-animation></a-entity>
        </a-entity>
    </a-scene>
    <!-- </div> -->
</template>

<script>
    import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
    import getModelUrl from '@/utils/promise.js'
    import { Toast } from "vant";
    import "vant/lib/index.css";
    export default {
        data() {
            return {
                modelUrl: ""
            }
        },
        created() {

        },
        // beforeDestroy() {
        //     modelElement.removeEventListener('model-loaded')
        // },
        async mounted() {
            let url = await getModelUrl(this.$route.query.modelUrl)
            if (sessionStorage.getItem('ar_state') == 1) {
                console.log('sessionStorage.getItem');
                sessionStorage.setItem('ar_state', '2')
                setTimeout(() => {
                    //  window.location.reload()
                }, 1000);
            }
            if (url != 'null' && url != 'undefined' && url != '') {
                getModelUrl(this.$route.query.modelUrl).then(modelUrl => {
                    console.log(modelUrl);
                    if (modelUrl) {
                        const myEntity = document.querySelector("#model")
                        myEntity.setAttribute('play-animation', '')
                        console.log(myEntity)
                        AFRAME.registerComponent('play-animation', {
                            init: function () {
                                let currentAnimation;
                                var el = this.el;
                                const modelElement = document.querySelector('#model');
                                modelElement.setAttribute('gltf-model', modelUrl)
                                var model = modelElement.object3D;
                                // var loader = new THREE.GLTFLoader();
                                var loader = new FBXLoader()
                                loader.load(
                                    // "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1694590851029.fbx",
                                    'https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1695116512061.fbx',
                                    function (child) {
                                        if (child.animations.length > 0) {
                                            for (var i = 0; i < child.animations.length; i++) {
                                                currentAnimation = child.animations[0]
                                                console.log(currentAnimation)
                                                // 获取动画
                                                var animations = [];
                                                animations.push(currentAnimation);
                                                console.log(animations)
                                                if (animations && animations.length) {
                                                    console.log("animations && animations.length")
                                                    modelElement.addEventListener('model-loaded', function (e) {
                                                        var model2 = e.detail.model;
                                                        console.log(model2)
                                                        var group = model.children[0];
                                                        console.log(group)
                                                        // 创建动画混合器
                                                        var mixer = new THREE.AnimationMixer(model2);
                                                        // 播放指定的动画
                                                        var action = mixer.clipAction(animations[0]);
                                                        action.play();

                                                        el.animationMixer = mixer;

                                                        el.addEventListener('animation-loop', function () {
                                                            console.log('animation looped');
                                                        });
                                                    });
                                                }
                                            }
                                        }
                                    }, function (xhr) {
                                        // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                                    },

                                    function (error) {
                                        // console.log(error);
                                        console.log('An error happened');
                                    })

                            },
                            tick: function (time, delta) {
                                if (this.el.animationMixer) {
                                    this.el.animationMixer.update(delta / 1000);
                                }
                            }
                        });
                    } else {
                        console.log(modelUrl);
                        console.log('失败');
                    }
                }, function (err) {
                    Toast.fail(this.$t('missingParameters'))
                })
            }
        },
        methods: {
        },
        beforeDestroy() {
            this.cameraState = 'no'
        },
    }
</script>

<style scoped>
    #as {
        width: 100vw;
        height: 100vh;
    }

    button {
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 20px;
    }
</style>